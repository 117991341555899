import { onlyValidadorOrAdminUsers } from '@/router/guards'

export default [
  {
    name: 'Importação de Nível de Tensão Histórico',
    path: 'importacao-nivel-tensao/historico',
    component: () => import('@/views/dados/importacao-nivel-tensao/Index.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', to: '/dados', exact: true },
        { text: 'Importações de Nível de Tensão', to: '/dados/importacao-nivel-tensao/historico', exact: false },
      ])
    },
    beforeEnter: onlyValidadorOrAdminUsers
  },
  {
    name: 'Importação de Nível de Tensão Add',
    path: 'importacao-nivel-tensao/add',
    component: () => import('@/views/dados/importacao-nivel-tensao/Add.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', to: '/dados', exact: true },
        { text: 'Importações de Nível de Tensão', to: '/dados/importacao-nivel-tensao/historico', exact: true },
        { text: 'Nova Importação', exact: false },
      ])
    },
    beforeEnter: onlyValidadorOrAdminUsers
  },
  {
    name: 'Importação de Nível de Tensão Logs',
    path: 'importacao-nivel-tensao/logs/:id',
    component: () => import('@/views/dados/importacao-nivel-tensao/Logs.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', to: '/dados', exact: true },
        { text: 'Importações de Nível de Tensão', to: '/dados/importacao-nivel-tensao/historico', exact: true },
        { text: 'Logs', exact: false },
      ])
    },
    beforeEnter: onlyValidadorOrAdminUsers
  },
]