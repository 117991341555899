<template>
  <v-container fluid class="mt-5">
    <v-row class="mb-8">
      <v-col>
        <h2>Dashboard Nível de Tensão</h2>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-btn color="primary" @click="dialogMetasKpis = true">
          Metas dos KPIs
          <v-icon class="ml-2">
            mdi-open-in-app
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs v-model="tab" fixed-tabs color="primary">
      <v-tab>
        Global
      </v-tab>
      <v-tab>
        Geral
      </v-tab>
      <v-tab>
        Municípios
      </v-tab>
      <v-tab>
        Unidade Consumidora
      </v-tab>
      <!-- <v-tab>
        Medidores Amostrais
      </v-tab>  -->
      <v-tab>
        Comparativo
      </v-tab>
    </v-tabs>

    <v-card class="my-0 parametros-v-card">
      <v-card-title>
        <h4>Parâmetros</h4>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto mt-2" justify="start">
          <v-col cols="12" sm="6" md="4">
            <input-month
              label="Competência de"
              rules="min:7"
              :minMonth="'2000-01'"
              :selectedMonth="competenciaDe"
              @monthSelected="setCompetenciaDe"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <input-month
              label="Competência Até"
              rules="min:7"
              :minMonth="'2000-01'"
              :selectedMonth="competenciaAte"
              @monthSelected="setCompetenciaAte"
            />
          </v-col>

          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="(subTab == 2 && tab <= 1) || tab == 2"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaInspecoes"
              v-model="inspecoesSelecionadas"
              multiple
              small-chips
              label="Resultado da Inspeção"
              clearable
              @change="inspecoesSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="(subTab == 0 && tab <= 1) || tab == 2 || tab == 3 || tab == 5"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaMedicoes"
              v-model="medicoesSelecionadas"
              multiple
              small-chips
              label="Resultado da Medição"
              clearable
              @change="medicoesSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="(subTab == 0 && tab <= 1) || tab == 2 || tab == 3 || tab == 5"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaOrigemMedicoes"
              v-model="origemMedicoesSelecionadas"
              multiple
              small-chips
              label="Origem da Medição"
              clearable
              @change="origemMedicoesSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="(subTab == 0 && tab <= 1) || tab == 2 || tab == 3 || tab == 5"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaTensaoReferencia"
              v-model="tensaoReferenciaSelecionadas"
              multiple
              small-chips
              label="Tensão de Referência"
              clearable
              @change="tensaoReferenciaSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="tab == 3 || tab == 5"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaLocalizacaoUc"
              v-model="localizacaoUcSelecionadas"
              multiple
              small-chips
              label="Localização"
              clearable
              @change="localizacaoUcSelecionadasIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tabs-items
      v-model="tab"
      style="background-color: transparent !important;"
    >
      <v-tab-item>
        <dashboard-nivel-tensao-visao-global
          ref="visaoGlobal"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :inspecoesSelecionadas="inspecoesSelecionadas"
          :medicoesSelecionadas="medicoesSelecionadas"
          :origemMedicoesSelecionadas="origemMedicoesSelecionadas"
          :tensaoReferenciaSelecionadas="tensaoReferenciaSelecionadas"
          :categories="categories"
          :activatedTab="tab"
          :subTab.sync="subTab"
          :traducaoIndicadores="traducaoIndicadores"
          :metasKpis="metasKpis"
        />
      </v-tab-item>

      <v-tab-item>
        <dashboard-nivel-tensao-visao-geral
          ref="visaoGeral"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :inspecoesSelecionadas="inspecoesSelecionadas"
          :medicoesSelecionadas="medicoesSelecionadas"
          :origemMedicoesSelecionadas="origemMedicoesSelecionadas"
          :tensaoReferenciaSelecionadas="tensaoReferenciaSelecionadas"
          :categories="categories"
          :activatedTab="tab"
          :subTab.sync="subTab"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>
      <v-tab-item>
        <dashboard-nivel-tensao-visao-municipios
          ref="visaoMunicipios"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :inspecoesSelecionadas="inspecoesSelecionadas"
          :medicoesSelecionadas="medicoesSelecionadas"
          :origemMedicoesSelecionadas="origemMedicoesSelecionadas"
          :tensaoReferenciaSelecionadas="tensaoReferenciaSelecionadas"
          :categories="categories"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
          :metasKpis="metasKpis"
        />
      </v-tab-item>

      <v-tab-item>
        <dashboard-nivel-tensao-visao-unidade-consumidora
          ref="visaoUnidadeConsumidora"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :medicoesSelecionadas="medicoesSelecionadas"
          :origemMedicoesSelecionadas="origemMedicoesSelecionadas"
          :tensaoReferenciaSelecionadas="tensaoReferenciaSelecionadas"
          :localizacaoUcSelecionadas="localizacaoUcSelecionadas"
          :categories="categories"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>

      <!-- <v-tab-item>
        <dashboard-nivel-tensao-visao-medidores-amostrais
          ref="visaoMedidoresAmostrais"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :activatedTab="tab"
        />
      </v-tab-item> -->

      <v-tab-item>
        <dashboard-nivel-tensao-visao-comparativo
          ref="visaoComparativo"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :medicoesSelecionadas="medicoesSelecionadas"
          :origemMedicoesSelecionadas="origemMedicoesSelecionadas"
          :tensaoReferenciaSelecionadas="tensaoReferenciaSelecionadas"
          :localizacaoUcSelecionadas="localizacaoUcSelecionadas"
          :categories="categories"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-overlay :value="loadingDados" :opacity="0.85">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>

    <dialog-gerenciamento-metas-kpis
      v-if="dialogMetasKpis"
      :dialog.sync="dialogMetasKpis"
      ref="metasKpis"
      @importaMetasKpis="importaMetasKpis()"
      @baixarMetasKpis="baixarMetasKpis($event)"
    >
      <template v-slot:cadatro-geral-extra-info>
        <p class="font-weight-regular">
          Deve conter as colunas:
          <b>
            mes; ano; qrt; prp; qtd_reclamacoes_totais; qtd_reclamacoes_procedentes; psfpg;
            psfpt_crp; psfpt_cri; psfpt_rmp; psfpt_rmc; total_compensacao; plna; drpe; drce
          </b>
        </p>
      </template>
      <template v-slot:cadatro-municipios-extra-info>
        <p class="font-weight-regular">
          Deve conter as colunas:
          <b>
            codigo_municipio; mes; ano; qrt; prp; qtd_reclamacoes_totais; qtd_reclamacoes_procedentes; psfpg; psfpt_crp; 
            psfpt_cri; psfpt_rmp; psfpt_rmc; total_compensacao; plna; drpe; drce; prioritario_plna; prioritario_psfpg
          </b>
        </p>
        <p class="font-weight-regular">
          <b>Obs.:</b> Para municípios prioritários, preencher com <b>Sim</b> ou <b>S</b> nas colunas <b>prioritario_plna</b> ou <b>prioritario_psfpg</b>
        </p>
      </template>
    </dialog-gerenciamento-metas-kpis>
  </v-container>
</template>

<script>
  import { generateMonthlyDateRangeArray } from "@/utils/dateUtils.js";

  import Highcharts from "highcharts";

  import HighchartsNoData from "highcharts/modules/no-data-to-display";
  import HighchartsBoost from "highcharts/modules/boost";
  import HighchartsExporting from "highcharts/modules/exporting";
  import HighchartsExportData from "highcharts/modules/export-data";
  import HighchartsDrilldown from "highcharts/modules/drilldown";
  import HighchartsData from "highcharts/modules/data";
  import HighchartsAccessibility from "highcharts/modules/accessibility";
  import { mapActions, mapState } from "vuex";
  HighchartsNoData(Highcharts);
  HighchartsBoost(Highcharts);
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
  HighchartsDrilldown(Highcharts);
  HighchartsData(Highcharts);
  HighchartsAccessibility(Highcharts);

  import DashboardNivelTensaoService from "@/services/DashboardNivelTensaoService";

  export default {
    name: "DashboardNivelTensao",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
      DashboardNivelTensaoVisaoGlobal: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoGlobal.vue"
        ),
      DashboardNivelTensaoVisaoGeral: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoGeral.vue"
        ),
      DashboardNivelTensaoVisaoMunicipios: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoMunicipios.vue"
        ),
      DashboardNivelTensaoVisaoUnidadeConsumidora: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoUnidadeConsumidora.vue"
        ),
      DashboardNivelTensaoVisaoMedidoresAmostrais: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoMedidoresAmostrais.vue"
        ),
      DashboardNivelTensaoVisaoComparativo: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoComparativo.vue"
        ),
      DialogGerenciamentoMetasKpis: () =>
        import(
          "@/components/paineisGerenciais/DialogGerenciamentoMetasKpis.vue"
        ),
    },
    data: () => ({
      loadingDados: false,
      tab: 0,
      subTab: 0,
      listaInspecoes: ["Não adequada", "Regularizada", "Adequada"],
      inspecoesSelecionadosIsDirty: false,
      listaMedicoes: ["NC", "CO"],
      medicoesSelecionadosIsDirty: false,
      listaOrigemMedicoes: ["AMS", "PER", "EVE"],
      origemMedicoesSelecionadosIsDirty: false,
      listaTensaoReferencia: [380, 13800, 220],
      tensaoReferenciaSelecionadosIsDirty: false,
      listaLocalizacaoUc: ["NURB", "URB"],
      localizacaoUcSelecionadasIsDirty: false,
      
      dialogMetasKpis: false,
      metasKpis: {},

      traducaoIndicadores: {
        // Reclamação
        qrt: "Quantidade Relativa de Reclamações",
        prp: "Percentual de Reclamações Procedentes",
        qtd_reclamacoes_totais: "Quantidade Reclamações Totais",
        qtd_reclamacoes_procedentes: "Quantidade Reclamações Procedentes",
        // Qualidade Serviços
        irnt: "Índice de Regularização do Nível de Tensão",
        psfpg:
          "Percentual De Serviços Comerciais Fora Do Prazo Por Grupo De Serviço",
        psfpt_crp:
          "Percentual De Serviços Comerciais Fora Do Prazo Por Tipo De Serviço (CRP)",
        psfpt_cri:
          "Percentual De Serviços Comerciais Fora Do Prazo Por Tipo De Serviço (CRI)",
        psfpt_rmp:
          "Percentual De Serviços Comerciais Fora Do Prazo Por Tipo De Serviço (RMP)",
        psfpt_rmc:
          "Percentual De Serviços Comerciais Fora Do Prazo Por Tipo De Serviço (RMC)",
        total_compensacao: "Total de Compensação",
        // Indicadores Técnicos
        icnt: "Índice de Conformidade do Nível de Tensão",
        plna: "Percentual de Leituras de Tensão não Adequadas",
        ucs_nao_conforme: "Quantidade de UCs Não Conforme",
        drpe:
          "Duração Relativa de Transgressão para Tensão Precária Equivalente",
        drce:
          "Duração Relativa de Transgressão para Tensão Crítica Equivalente",
        drpe_mensal:
          "Duração Relativa de Transgressão para Tensão Precária Equivalente (Mensal)",
        drce_mensal:
          "Duração Relativa de Transgressão para Tensão Crítica Equivalente (Mensal)",
      },
    }),
    computed: {
      ...mapState('parametrosDashboardNivelTensao', [
        'competenciaDe',
        'competenciaAte',
        'inspecoes',
        'medicoes',
        'origemMedicoes',
        'tensaoReferencia',
        'localizacaoUc',
      ]),
      inspecoesSelecionadas: {
        get() {
          return this.inspecoes;
        },
        set(newValue) {
          this.setInspecoes(newValue);
        }
      },
      medicoesSelecionadas: {
        get() {
          return this.medicoes;
        },
        set(newValue) {
          this.setMedicoes(newValue);
        }
      },
      origemMedicoesSelecionadas: {
        get() {
          return this.origemMedicoes;
        },
        set(newValue) {
          this.setOrigemMedicoes(newValue);
        }
      },
      tensaoReferenciaSelecionadas: {
        get() {
          return this.tensaoReferencia;
        },
        set(newValue) {
          this.setTensaoReferencia(newValue);
        }
      },
      localizacaoUcSelecionadas: {
        get() {
          return this.localizacaoUc;
        },
        set(newValue) {
          this.setLocalizacaoUc(newValue);
        }
      },
      categories() {
        const startDate = this.competenciaDe;
        const endDate = this.competenciaAte;

        return generateMonthlyDateRangeArray(startDate, endDate, "YYYY/MM");
      },
    },
    mounted() {
      this.getMetasKpisGeral();
    },
    methods: {
      ...mapActions('parametrosDashboardNivelTensao', [
        'setCompetenciaDe',
        'setCompetenciaAte',
        'setInspecoes',
        'setMedicoes',
        'setOrigemMedicoes',
        'setTensaoReferencia',
        'setLocalizacaoUc',
      ]),
      atualizaDados() {
        if (
          !this.inspecoesSelecionadosIsDirty &&
          !this.medicoesSelecionadosIsDirty &&
          !this.origemMedicoesSelecionadosIsDirty && 
          !this.tensaoReferenciaSelecionadosIsDirty &&
          !this.localizacaoUcSelecionadasIsDirty
        ) {
          return;
        }

        this.$refs?.visaoGlobal?.getDados();
        this.$refs?.visaoGeral?.getDados();
        this.$refs?.visaoMunicipios?.getDados();
        this.$refs?.visaoUnidadeConsumidora?.getDados();
        this.$refs?.visaoMedidoresAmostrais?.getDados();
        this.$refs?.visaoComparativo?.getDados();

        this.inspecoesSelecionadosIsDirty = false;
        this.medicoesSelecionadosIsDirty = false;
        this.origemMedicoesSelecionadosIsDirty = false;
        this.tensaoReferenciaSelecionadosIsDirty = false;
        this.localizacaoUcSelecionadasIsDirty = false;
      },
      importaMetasKpis() {
        const ref = this.$refs.metasKpis;

        ref.loading = true;

        DashboardNivelTensaoService.importarMetasKpis(
          ref.tipoDeCadastro,
          ref.lastUploadedFile.id
        )
          .then((res) => {
            this.$toast.success("Metas salvas com sucesso", "", {
              position: "topRight",
            });
            ref.uploadedFiles = [];
            ref.dialogLocal = false;
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao salvar metas", "", {
              position: "topRight",
            });
          })
          .finally(() => (ref.loading = false));
      },
      baixarMetasKpis(tipoDeCadastro) {
        const ref = this.$refs.metasKpis;

        ref.loading = true;

        DashboardNivelTensaoService.baixarMetasKpis(tipoDeCadastro, {
          timeout: 60 * 60 * 1000,
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Metas dos KPIs - ${tipoDeCadastro}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao baixar Metas dos KPIs", "", {
              position: "topRight",
            });
          })
          .finally(() => (ref.loading = false));
      },
      getMetasKpisGeral() {
        return DashboardNivelTensaoService.getMetasKpisGeral(
          this.competenciaDe,
          this.competenciaAte,
        )
          .then(res => {
            this.metasKpis = res;
          })
          .catch(err => {
            throw err;
          });
      },
    },
    watch: {
      competenciaDe() {
        this.getMetasKpisGeral();
      },
      competenciaAte() {
        this.getMetasKpisGeral();
      },
    },
  };
</script>

<style>
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
</style>
