<template>
  <span>
    <highcharts :options="chartOptions" :deepCopyOnUpdate="false" />
  </span>
</template>

<script>
  import columnChartMixin from "./columnChartMixin";

  export default {
    name: "StackedColumnChartMensal",
    mixins: [columnChartMixin],
    props: {
      dadosMensais: {
        type: Object,
        required: true,
      },
      seriesNames: {
        type: Array,
        required: true,
      },
      metasKpis: {
        type: Object,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      }
    },
    data: () => ({
      chartOptions: {},
    }),
    mounted() {
      this.setChartOptions();
    },
    methods: {
      setChartOptions() {
        this.chartOptions = this.getColumnChartOptions(
          this.getRemainingChartOptions()
        );
      },
      getRemainingChartOptions() {
        return {
          xAxis: {
            type: "category",
            categories: this.categories,
            title: {
              text: "Competência",
            },
          },
          yAxis: {
            title: {
              text: null,
            },
            stackLabels: {
              enabled: true,
            },
          },
          legend: { enabled: true },
          tooltip: { 
            shared: true,
            followPointer: true,
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: this.seriesNames.map((serieName) => {
            return {
              name: serieName.toUpperCase().replace("_", " "),
              data: this.categories
                .map((category) => {
                  return category in this.dadosMensais
                    ? {
                        x: this.categories.indexOf(category),
                        y: Number(this.dadosMensais[category][serieName]),
                      }
                    : {
                        x: this.categories.indexOf(category),
                        y: 0,
                      };
                })
                .filter((val) => val !== null && val.y !== null && val.y > 0),
            };
          }),
        };
      },
    },
    watch: {
      dadosMensais() {
        this.setChartOptions();
      },
    },
  };
</script>

<style></style>
