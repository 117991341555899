import indgerExports from './indger-exports'
import consultaAprovacoesTemas from './consulta-aprovacoes-temas'
import manutencaoTecnicos from './manutencao-tecnicos'
import importacaoNivelTensao from './importacao-nivel-tensao'

let children = [
  {
    name: 'Dados',
    path: '',
    component: () => import('@/views/dados/Index'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', exact: false }
      ])
    }
  }
]

children = children.concat(
  indgerExports,
  consultaAprovacoesTemas,
  manutencaoTecnicos,
  importacaoNivelTensao
)

export default [{
  path: '/dados',
  component: () => import('@/views/Index'),
  children
}]