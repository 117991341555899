<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie o arquivo de dados a ser importado
    </div>

    <v-row class="mx-auto">
      <v-col cols="12">
        <v-checkbox 
          label="Apagar base atual" 
          v-model="apagarBaseAtual" 
          class="mt-0"
          hide-details
        />
      </v-col>
      <v-col cols="12" v-if="apagarBaseAtual === true">
        <v-alert
          dense 
          outlined 
          type="warning" 
          class="mb-4 py-1 px-1"
        >
          <div>
            <strong>Atenção:</strong> essa opção apagará os dados existentes na base antes de importar os novos, incluindo as aprovações de indicadores. Se desejar apenas adicionar dados novos ou atualizar registros existentes, desmarque esta opção.
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="mx-auto" v-if="isBaseIndger && !apagarBaseAtual">
      <v-col cols="12">
        <v-checkbox 
          label="Apagar aprovações atuais" 
          v-model="apagarAprovacoesAtuais" 
          class="mt-0"
          hide-details
        />
      </v-col>
    </v-row>

    <v-row v-if="!apagarBaseAtual && isBaseIndger" class="mx-auto mb-8">
      <v-col cols="12">
        <v-expansion-panels accordion multiple>
          <v-expansion-panel
            v-if="!apagarBaseAtual && formattedFieldsCategories.length > 0"
          >
            <v-expansion-panel-header>Campos Atualizados</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col cols="12">
                  <h4>Categorias de Campos</h4>
                </v-col>
                <v-col class="pb-0 pt-0" cols="12">
                  <v-checkbox 
                    v-model="selectAllCategories"
                    label="Selecionar tudo"
                    hide-details
                    class="mt-0"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-chip-group
                    v-model="selectedCategories"
                    column
                    multiple
                  >
                    <v-chip
                      v-for="category in formattedFieldsCategories" 
                      :key="category.slug"
                      :ripple="false"
                      filter
                      outlined
                      :value="category.slug"
                    >
                      {{ category.category }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>

              <v-row dense class="mt-4">
                <v-col cols="12">
                  <h4>Campos</h4>
                </v-col>
                <v-col v-if="selectedFields.length < 1" cols="12" class="caption">
                  <strong style="color: red;">Selecione pelo menos 1 campo</strong>
                </v-col>
                <v-col class="pb-0 pt-0" cols="12">
                  <v-checkbox 
                    v-model="selectAllFields"
                    label="Selecionar tudo"
                    hide-details
                    class="mt-0"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-chip-group
                    v-model="selectedFields"
                    column
                    mandatory
                    multiple
                  >
                    <v-chip
                      v-for="fields in availableFields" 
                      :key="fields"
                      filter
                      outlined
                      :value="fields"
                    >
                      {{ fields | toUpperCase }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            v-if="
              !apagarBaseAtual && 
              formattedFieldsCategories.length > 0 && 
              dataImportType.tipo === 'IndicadoresServicosV2'
            "
          >
              <v-expansion-panel-header>Tipos de Serviço Atualizados</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col cols="12">
                    <h4>Categorias de Serviços</h4>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selecionar_todos_servicos"
                      label="Selecionar tudo"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-chip-group
                      v-model="servicosEmSelecionamento"
                      column
                      multiple
                    >
                      <v-chip
                        v-for="categoria in servicosCategories" 
                        :key="categoria.id"
                        :ripple="false"
                        filter
                        outlined
                        :value="categoria.id"
                      >
                        {{ categoria.nome }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>

                <v-row dense class="mt-4">
                  <v-col cols="12">
                    <h4>Tipos de Serviços</h4>
                  </v-col>
                  <v-col v-if="tiposServicosEmSelecionamento.length < 1" cols="12" class="caption">
                    <strong style="color: red;">Selecione pelo menos 1 tipo de serviço</strong>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selecionarTodosTiposServicos"
                      label="Selecionar tudo"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-chip-group
                      v-model="tiposServicosEmSelecionamento"
                      column
                      mandatory
                      multiple
                    >
                      <v-chip
                        v-for="tipoServico in tiposServicos" 
                        :key="tipoServico"
                        filter
                        outlined
                        :value="tipoServico"
                      >
                        {{ tipoServico }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row
      id="importacao-encoding"
      class="mx-auto"
    >
      <v-col cols=12>
        <select-encoding
          id="fs-encoding-importacao-dados"
          label="Codificação do arquivo"
          type="outlined"
          :selected="encoding"
          @encoding:selected="encodingSelected"
        />
      </v-col>
    </v-row> 

    <v-row id="importacao-upload-files">
      <v-col cols=12>
        <upload-files
          id="fs-upload-importacao-dados" 
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        >
          <template v-slot:upload-alert-extra-information>
            <link-download-arquivo-modelo
              id="fs-link-download-importacao-dados"
              :tipoImportacao="dataImportType"
            />
          </template>
        </upload-files>
      </v-col>
    </v-row>
    <v-row v-if="lastUploadedFile">
      <v-col cols="12">
        <v-text-field
          label="Arquivo enviado"
          outlined
          readonly
          v-model="lastUploadedFile.arquivo"
        />
      </v-col>
    </v-row>    
  </div>    
</template>

<script>

import ImportacoesDadosService from '@/services/ImportacoesDadosService';

export default {
  name: 'SecondTabContent',
  components: {
    SelectEncoding: () => import('@/components/general/SelectEncoding.vue'),
    UploadFiles: () => import('@/components/general/UploadFiles/Index.vue'),
    LinkDownloadArquivoModelo: () => import('@/components/administracao/importacoes-dados/LinkDownloadArquivoModelo.vue')
  },
  props: {
    dataImportType: {
      type: Object,
      default: null 
    },
    formattedFieldsCategories: {
      type: Array,
      required: true 
    },
    servicosCategories: {
      type: Array,
      required: true 
    },
  },
  data() {
    return {
      encoding: 'UTF-8',
      allowedFiles: [],
      uploadedFiles: [],
      apagarBaseAtual: false,  
      apagarAprovacoesAtuais: false,  
      selectAllCategories: false,
      selectedCategories: [],
      selectAllFields: false,
      selectedFields: [],
      servicosEmSelecionamento: [],
      selecionar_todos_servicos: false,
      tiposServicosEmSelecionamento: [],
      selecionarTodosTiposServicos: false,
    }  
  }, 
  mounted() {
    this.getAllowedFiles();
    this.encoding = this.dataImportType.encoding;  
    
    this.selectAllCategories = true;
    this.selecionar_todos_servicos = true;
  },
  watch: {
    isBaseIndger() {
      if (!this.isBaseIndger) {
        this.apagarAprovacoesAtuais = false;
      }
    },
    encoding() {
      this.$emit('encoding:selected', this.encoding);  
    },
    apagarBaseAtual() {
      this.$emit('apagarBaseAtual:changed', this.apagarBaseAtual);  

      this.selectedCategories = this.formattedFieldsCategories.map(category => category.slug);
      this.servicosEmSelecionamento = this.servicosCategories.map(categoria => categoria.id);
      this.tiposServicosEmSelecionamento = this.tiposServicos;

      if (this.apagarAprovacoesAtuais === true) {
        this.apagarAprovacoesAtuais = false;
      }
    },
    apagarAprovacoesAtuais() {
      this.$emit('apagarAprovacoesAtuais:changed', this.apagarAprovacoesAtuais);
    },
    uploadedFiles() {
      this.$emit('file:uploaded', this.lastUploadedFile);
    },
    dataImportType() {
      this.encoding = this.dataImportType.encoding;
      this.selectedCategories = this.formattedFieldsCategories.map(category => category.slug);
    },
    selectAllCategories() {
      this.selectedCategories = this.selectAllCategories 
        ? this.formattedFieldsCategories.map(category => category.slug)
        : [];
    },
    selectedCategories() {
      this.$emit('selectedCategories:changed', this.selectedCategories);

      this.setSelectedFieldsByCategories();
    },
    selectAllFields() {
      this.selectedFields = this.selectAllFields 
        ? this.availableFields
        : [];
    },
    selectedFields() {
      this.$emit('selectedFields:changed', this.selectedFields);
    },
    servicosEmSelecionamento() {
      this.$emit('selectedServicesCategories:changed', this.servicosEmSelecionamento);

      this.setSelectedTiposServicosByCategoriasServicos();
    },
    selecionar_todos_servicos() {
      this.servicosEmSelecionamento = this.selecionar_todos_servicos 
        ? this.servicosCategories.map(categoria => categoria.id) 
        : [];
    },
    tiposServicosEmSelecionamento() {
      this.$emit('selectedTiposServicos:changed', this.tiposServicosEmSelecionamento);
    },
    selecionarTodosTiposServicos() {
      this.tiposServicosEmSelecionamento = this.selecionarTodosTiposServicos
        ? this.tiposServicos
        : [];
    },
  },
  computed: {
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    },
    isBaseIndger() {
      const bases_indger = [
        "IndicadoresAlimentadores", 
        "IndicadoresComerciaisV2", 
        "IndicadoresLD", 
        "IndicadoresServicosV2", 
        "IndicadoresSubestacoes"
      ];

      return bases_indger.includes(this.dataImportType.tipo);
    },
    availableFields() {
      let fields = this.formattedFieldsCategories.map(el => el.fields).flat();

      // caso de o campo estiver em mais de uma categoria
      return Array.from(new Set(fields));
    },
    tiposServicos() {
      let tiposServicos = this.servicosCategories.map(el => el.tipos_servicos)
        .flat()
        .sort((a,b) => a - b);

      // caso de o serviço estiver em mais de uma categoria
      return Array.from(new Set(tiposServicos));
    },
  },
  methods: {
    setSelectedTiposServicosByCategoriasServicos() {
      let tiposServicos = [];

      this.servicosEmSelecionamento.forEach(servicoId => {
        let category = this.servicosCategories.find(category => category.id === servicoId);
        
        tiposServicos = tiposServicos.concat(category.tipos_servicos);
      });
      
      // caso de o serviço estiver em mais de uma categoria
      this.tiposServicosEmSelecionamento = Array.from(new Set(tiposServicos));
    }, 
    setSelectedFieldsByCategories() {
      let fields = [];

      this.selectedCategories.forEach(categorySlug => {
        let category = this.formattedFieldsCategories.find(category => category.slug === categorySlug);
        
        fields = fields.concat(category.fields);
      });
      
      // caso de o campo estiver em mais de uma categoria
      this.selectedFields = Array.from(new Set(fields));
    },
    getAllowedFiles() {
      ImportacoesDadosService
        .getAllowedFiles()
        .then(response => this.allowedFiles = response.data)
        .catch(error => console.log('Error: ' + error));    
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach(file => this.uploadedFiles.push(file));
    },
    encodingSelected(event) {
      this.encoding = event;
    }
  }
}

</script>
