<template>
  <span>
    <v-card class="mb-0 pb-1 mt-n4 parametros-v-card">
      <v-row justify="start" class="mx-auto mt-0">
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-select
            class="ml-8"
            :items="regionaisList"
            :value="regionalSelecionada"
            @change="setRegionalSelecionada"
            label="Regional selecionado"
          />
        </v-col>
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-autocomplete
            class="ml-5"
            :items="listaMunicipios"
            v-model="municipioSelecionado"
            label="Município selecionado"
            clearable
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row dense justify="center">
      <v-col cols="12" md="8" class="flex-md-grow-1">
        <v-card class="my-1">
          <v-card-title>
            <h4>Mapa Coroplético</h4>
          </v-card-title>
          <v-card-text>
            <mapa-coropletico-municipios
              :labelKpis="{
                compensacao: 'Compensação',
                qtd_protocolos: 'Quantidade de Protocolos',
                qtd_dias_submetidos_nt_inadequado:
                  'Quant. UCs com Tensão Não Conforme',
              }"
              :dadosPorMunicipio="dadosMapaMunicipiosByUcs"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="auto">
        <v-switch
          class="switch-tipo-visao"
          color="primary"
          dense
          hide-details
          :false-value="'municipios'"
          :true-value="'ucs'"
          v-model="tipoVisao"
        >
          <template #prepend>
            <label
              class="v-label"
              :class="
                tipoVisao == 'municipios'
                  ? 'text--primary'
                  : 'text--secondary font-weight-light'
              "
            >
              Por Municípios
            </label>
          </template>
          <template #append>
            <label
              class="v-label"
              :class="
                tipoVisao == 'ucs'
                  ? 'text--primary'
                  : 'text--secondary font-weight-light'
              "
            >
              Por UCs
            </label>
          </template>
        </v-switch>
      </v-col>
    </v-row>

    <v-row v-if="tipoVisao === 'ucs'" dense justify="center" class="mt-5">
      <v-col cols="12" md="5">
        <v-data-table
          :loading="loadingItemsGroupedUcs"
          v-model="ucSelecionada"
          :headers="headersTableGroupedUcs"
          :items="itemsGroupedUcs"
          class="elevation-3 data-table-slim pt-3"
          dense
          multi-sort
          single-select
          show-select
          item-key="uc"
          :options="{
            itemsPerPage: 10,
          }"
          :headerProps="{
            sortByText: 'Ordenar Por',
          }"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [5, 15, 50, 100, -1],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
        >
          <template v-slot:header.uc="{ header }">
            <div class="caption font-weight-bold">
              Total:
              {{ totaisItemsGroupedByUcs.uc | parseNumberToFloatBrIgnoringNaN }}
            </div>
            {{ header.text }}
          </template>
          <template v-slot:header.municipio="{ header }">
            <div class="caption font-weight-bold">
              Total:
              {{
                totaisItemsGroupedByUcs.municipio
                  | parseNumberToFloatBrIgnoringNaN
              }}
            </div>
            {{ header.text }}
          </template>
          <template v-slot:header.compensacao="{ header }">
            <div class="caption font-weight-bold">
              Total:
              {{
                totaisItemsGroupedByUcs.compensacao
                  | parseNumberToFloatBrIgnoringNaN
              }}
            </div>
            {{ header.text }}
          </template>
          <template v-slot:header.qtd_protocolos="{ header }">
            <div class="caption font-weight-bold">
              Total:
              {{
                totaisItemsGroupedByUcs.qtd_protocolos
                  | parseNumberToFloatBrIgnoringNaN
              }}
            </div>
            {{ header.text }}
          </template>

          <template v-slot:[`item.compensacao`]="{ item }">
            {{ item.compensacao | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template
            v-slot:[`item.qtd_dias_submetidos_nt_inadequado`]="{ item }"
          >
            {{
              item.qtd_dias_submetidos_nt_inadequado
                | parseNumberToFloatBrIgnoringNaN
            }}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="ucSelecionada.length" cols="12" md="7">
        <v-data-table
          :loading="loadingItemsByUc"
          :headers="headersTableByUc"
          :items="itemsByUc"
          class="elevation-3 data-table-slim pt-3"
          dense
          multi-sort
          :options="{
            itemsPerPage: 10,
          }"
          :headerProps="{
            sortByText: 'Ordenar Por',
          }"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [5, 15, 50, 100, -1],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
        >
          <template v-slot:[`item.mes_referencia`]="{ item }">
            {{ item.mes_referencia | formatToMonth }}
          </template>
          <template v-slot:[`item.drp`]="{ item }">
            {{ item.drp | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template v-slot:[`item.drc`]="{ item }">
            {{ item.drc | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template v-slot:[`item.compensacao`]="{ item }">
            {{ item.compensacao | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template
            v-slot:[`item.qtd_dias_submetidos_nt_inadequado`]="{ item }"
          >
            {{
              item.qtd_dias_submetidos_nt_inadequado
                | parseNumberToFloatBrIgnoringNaN
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row
      v-if="tipoVisao === 'municipios'"
      dense
      justify="center"
      class="mt-5"
    >
      <v-col cols="12" md="5">
        <v-data-table
          :loading="loadingItemsGroupedMunicipios"
          v-model="municipioSelecionadoDt"
          :headers="headersTableGroupedMunicipios"
          :items="itemsGroupedMunicipios"
          class="elevation-3 data-table-slim pt-3"
          dense
          multi-sort
          single-select
          show-select
          item-key="codigo_municipio"
          :options="{
            itemsPerPage: 10,
          }"
          :headerProps="{
            sortByText: 'Ordenar Por',
          }"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [5, 15, 50, 100, -1],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
        >
          <template v-slot:header.municipio="{ header }">
            <div class="caption font-weight-bold">
              Total:
              {{
                totaisItemsGroupedByMunicipios.municipio
                  | parseNumberToFloatBrIgnoringNaN
              }}
            </div>
            {{ header.text }}
          </template>
          <template v-slot:header.compensacao="{ header }">
            <div class="caption font-weight-bold">
              Total:
              {{
                totaisItemsGroupedByMunicipios.compensacao
                  | parseNumberToFloatBrIgnoringNaN
              }}
            </div>
            {{ header.text }}
          </template>
          <template v-slot:header.qtd_ucs="{ header }">
            <div class="caption font-weight-bold">
              Total:
              {{
                totaisItemsGroupedByMunicipios.qtd_ucs
                  | parseNumberToFloatBrIgnoringNaN
              }}
            </div>
            {{ header.text }}
          </template>

          <template v-slot:[`item.compensacao`]="{ item }">
            {{ item.compensacao | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template
            v-slot:[`item.qtd_dias_submetidos_nt_inadequado`]="{ item }"
          >
            {{
              item.qtd_dias_submetidos_nt_inadequado
                | parseNumberToFloatBrIgnoringNaN
            }}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="municipioSelecionadoDt.length" cols="12" md="7">
        <v-data-table
          :loading="loadingItemsByMunicipio"
          :headers="headersTableByMunicipio"
          :items="itemsByMunicipio"
          class="elevation-3 data-table-slim pt-3"
          dense
          multi-sort
          :options="{
            itemsPerPage: 10,
          }"
          :headerProps="{
            sortByText: 'Ordenar Por',
          }"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [5, 15, 50, 100, -1],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
        >
          <template v-slot:[`item.mes_referencia`]="{ item }">
            {{ item.mes_referencia | formatToMonth }}
          </template>
          <template v-slot:[`item.drp`]="{ item }">
            {{ item.drp | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template v-slot:[`item.drc`]="{ item }">
            {{ item.drc | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template v-slot:[`item.compensacao`]="{ item }">
            {{ item.compensacao | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template
            v-slot:[`item.qtd_dias_submetidos_nt_inadequado`]="{ item }"
          >
            {{
              item.qtd_dias_submetidos_nt_inadequado
                | parseNumberToFloatBrIgnoringNaN
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  import DashboardNivelTensaoService from "@/services/DashboardNivelTensaoService";
  import MunicipiosService from "@/services/MunicipiosService";

  import { mapState, mapActions } from "vuex";

  export default {
    name: "DashboardNivelTensaoVisaoUnidadeConsumidora",
    components: {
      MapaCoropleticoMunicipios: () =>
        import("@/components/general/MapaCoropleticoMunicipios.vue"),
    },
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: {},
      },
      medicoesSelecionadas: {
        type: Array,
        required: true,
      },
      origemMedicoesSelecionadas: {
        type: Array,
        required: true,
      },
      tensaoReferenciaSelecionadas: {
        type: Array,
        required: true,
      },
      localizacaoUcSelecionadas: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      tipoVisao: "ucs",
      kpis: [""],
      regionais: [],
      listaMunicipios: [],
      dadosPorMunicipio: [],
      dadosPorCompetencia: {},
      dadosMapaMunicipiosByUcs: [],
      ucSelecionada: [],
      headersTableGroupedUcs: [
        { text: "UC", value: "uc" },
        { text: "Município", value: "municipio" },
        { text: "Compensação", value: "compensacao" },
        { text: "Quantidade de Protocolos", value: "qtd_protocolos" },
      ],
      headersTableByUc: [
        { text: "UC", value: "uc" },
        { text: "Protocolo", value: "protocolo_atendimento" },
        { text: "Competência", value: "mes_referencia" },
        { text: "Tensão de Referênncia", value: "tensao_referencia" },
        { text: "DRP", value: "drp" },
        { text: "DRC", value: "drc" },
        { text: "Origem da Medição", value: "origem_medicao" },
        { text: "Resultado da Medicao", value: "resultado_medicao" },
        { text: "Compensação", value: "compensacao" },
        {
          text: "Quant. de dias submetido a nível de tensão inadequado",
          value: "qtd_dias_submetidos_nt_inadequado",
        },
      ],
      loadingItemsGroupedUcs: false,
      loadingItemsByUc: false,
      itemsGroupedUcs: [],
      itemsByUc: [],
      municipioSelecionadoDt: [],
      headersTableGroupedMunicipios: [
        { text: "Município", value: "municipio" },
        { text: "Compensação", value: "compensacao" },
        { text: "Quantidade de UCs", value: "qtd_ucs" },
      ],
      headersTableByMunicipio: [
        { text: "UC", value: "uc" },
        { text: "Competência", value: "mes_referencia" },
        { text: "Protocolo", value: "protocolo_atendimento" },
        { text: "Tensão de Referênncia", value: "tensao_referencia" },
        { text: "DRP", value: "drp" },
        { text: "DRC", value: "drc" },
        { text: "Origem da Medição", value: "origem_medicao" },
        { text: "Resultado da Medicao", value: "resultado_medicao" },
        { text: "Compensação", value: "compensacao" },
        {
          text: "Quant. de dias submetido a nível de tensão inadequado",
          value: "qtd_dias_submetidos_nt_inadequado",
        },
      ],
      itemsGroupedMunicipios: [],
      itemsByMunicipio: [],
      loadingItemsGroupedMunicipios: false,
      loadingItemsByMunicipio: false,
    }),
    computed: {
      ...mapState('parametrosDashboardNivelTensao', [
        'regionalSelecionada',
        'municipio'
      ]),
      municipioSelecionado: {
        get() {
          return this.municipio;
        },
        set(newValue) {
          this.setMunicipio(newValue);
        }
      },
      regionaisList() {
        return this.regionais.map((el) => el.nome);
      },
      regionalSelecionadaId() {
        let regional = this.regionais.find(
          (element) => element.nome == this.regionalSelecionada
        );
        return regional == undefined ? 0 : regional.id;
      },
      totaisItemsGroupedByUcs() {
        return {
          compensacao: this.itemsGroupedUcs.reduce(
            (total, item) => total + parseFloat(item.compensacao),
            0
          ),
          qtd_protocolos: this.itemsGroupedUcs.reduce(
            (total, item) => total + parseFloat(item.qtd_protocolos),
            0
          ),
          uc: new Set(this.itemsGroupedUcs.map((item) => item.uc)).size,
          municipio: new Set(
            this.itemsGroupedUcs.map((item) => item.codigo_municipio)
          ).size,
        };
      },
      totaisItemsGroupedByMunicipios() {
        return {
          compensacao: this.itemsGroupedMunicipios.reduce(
            (total, item) => total + parseFloat(item.compensacao),
            0
          ),
          qtd_ucs: this.itemsGroupedMunicipios.reduce(
            (total, item) => total + parseFloat(item.qtd_ucs),
            0
          ),
          municipio: new Set(
            this.itemsGroupedMunicipios.map((item) => item.codigo_municipio)
          ).size,
        };
      },
    },
    async mounted() {
      await this.getRegionais(this.regionalSelecionadaId);
      this.getDados();
    },
    methods: {
      ...mapActions('parametrosDashboardNivelTensao', [
        'setRegionalSelecionada',
        'setMunicipio'
      ]),
      getRegionais(regional) {
        return MunicipiosService.getRegionais(regional)
          .then((res) => {
            this.regionais = res;
            if (this.regionalSelecionada == null) {
              this.setRegionalSelecionada(res.find(
                (el) => el.nome.toLowerCase() == "distribuidora"
              ).nome);
            }
          })
          .catch((err) => {
            throw err;
          });
      },
      getDados() {
        // Apenas atualizado os dados se for a visao selecionada
        if (this.activatedTab !== 3) return;

        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getDadosMapaMunicipiosUcs(),
          this.getItemsGroupedByUcs(),
          this.getItemsByUc(),
          this.getItemsGroupedByMunicipios(),
          this.getItemsByMunicipio()
        ])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false)
          });
      },
      getDadosDataTable() {
        // Apenas atualizado os dados se for a visao selecionada
        if (this.activatedTab !== 3) return;

        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getItemsGroupedByUcs(),
          this.getItemsByUc(),
          this.getItemsGroupedByMunicipios(),
          this.getItemsByMunicipio()
        ])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false)
          });
      },
      getDadosMapaMunicipiosUcs() {
        return DashboardNivelTensaoService.getDadosMapaMunicipiosUcs(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.medicoesSelecionadas,
          this.origemMedicoesSelecionadas,
          this.tensaoReferenciaSelecionadas,
          this.localizacaoUcSelecionadas
        )
          .then((res) => {
            this.dadosMapaMunicipiosByUcs = res;

            this.listaMunicipios = res
              .map((el) => {
                  return {
                    text: el.nome_municipio,
                    value: el.codigo_municipio
                  }
              })
              .sort((a, b) => Number(a ?? 0) - Number(b ?? 0));
          })
          .catch((err) => {
            throw err;
          });
      },
      getItemsGroupedByUcs() {
        if (this.tipoVisao !== "ucs") return;

        this.loadingItemsGroupedUcs = true;
        this.ucSelecionada = [];

        return DashboardNivelTensaoService.getItemsGroupedByUcs(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.municipioSelecionado,
          this.medicoesSelecionadas,
          this.origemMedicoesSelecionadas,
          this.tensaoReferenciaSelecionadas,
          this.localizacaoUcSelecionadas
        )
          .then((res) => {
            this.itemsGroupedUcs = res;
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingItemsGroupedUcs = false));
      },
      getItemsByUc() {
        if (!this.ucSelecionada.length) return;
        if (this.tipoVisao !== "ucs") return;

        this.loadingItemsByUc = true;

        return DashboardNivelTensaoService.getItemsByUc(
          this.ucSelecionada[0].uc,
          this.competenciaDe,
          this.competenciaAte
        )
          .then((res) => {
            this.itemsByUc = res;
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingItemsByUc = false));
      },
      getItemsGroupedByMunicipios() {
        if (this.tipoVisao !== "municipios") return;

        this.loadingItemsGroupedMunicipios = true;
        this.municipioSelecionadoDt = [];

        return DashboardNivelTensaoService.getItemsGroupedByMunicipios(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.municipioSelecionado,
          this.medicoesSelecionadas,
          this.origemMedicoesSelecionadas,
          this.tensaoReferenciaSelecionadas,
          this.localizacaoUcSelecionadas
        )
          .then((res) => {
            this.itemsGroupedMunicipios = res;
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingItemsGroupedMunicipios = false));
      },
      getItemsByMunicipio() {
        if (!this.municipioSelecionadoDt.length) return;
        if (this.tipoVisao !== "municipios") return;

        this.loadingItemsByMunicipio = true;

        return DashboardNivelTensaoService.getItemsByMunicipio(
          this.municipioSelecionadoDt[0].codigo_municipio,
          this.competenciaDe,
          this.competenciaAte
        )
          .then((res) => {
            this.itemsByMunicipio = res;
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingItemsByMunicipio = false));
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      },
      ucSelecionada() {
        this.getItemsByUc();
      },
      municipioSelecionadoDt() {
        this.getItemsByMunicipio();
      },
      tipoVisao() {
        this.getDadosDataTable();
      },
      regionalSelecionada() {
        this.getDados();
      },
      municipioSelecionado() {
        this.getDadosDataTable();
      },
    },
  };
</script>

<style>
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
  .data-table-slim table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
  }
  .data-table-slim table thead tr th {
    font-size: 12px;
    height: 25px;
    font-weight: 400;
  }

  .switch-tipo-visao .v-input__control {
    width: 32px !important;
  }
  .switch-tipo-visao
    .v-input--selection-controls__input
    .v-input--switch__track,
  .switch-tipo-visao
    .v-input--selection-controls__input
    .v-input--switch__thumb {
    color: #003a63 !important;
  }
</style>
