<template>
  <v-container fluid class="mt-5">
    <v-row class="mb-8">
      <v-col>
        <h2>Dashboard Ligação com Obras</h2>
      </v-col>
    </v-row>

    <v-tabs v-model="tab" fixed-tabs color="primary">
      <v-tab>
        Geral
      </v-tab>
      <v-tab>
        Visão Aneel
      </v-tab>
      <v-tab>
        Municípios
      </v-tab>
    </v-tabs>

    <v-card class="my-0 parametros-v-card">
      <v-card-title>
        <h4>Parâmetros</h4>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto mt-3 mb-0" justify="space-between">
          <v-col v-if="tab === 0" cols="12" sm="3">
            <input-month
              label="Competência de"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competenciaDe"
              @monthSelected="setCompetenciaDe"
            />
          </v-col>
          <v-col v-if="tab === 0" cols="12" sm="3">
            <input-month
              label="Competência Até"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competenciaAte"
              @monthSelected="setCompetenciaAte"
            />
          </v-col>
          <v-col v-if="tab !== 0" cols="12" sm="3">
            <input-month
              label="Competência"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competencia"
              @monthSelected="setCompetencia"
            />
          </v-col>
          <v-col v-if="tab !== 0" cols="12" sm="3">
            <v-select
              class="pt-0"
              :items="regionaisList"
              :value="regionalSelecionada"
              @change="setRegionalSelecionada"
              label="Regional selecionado"
            />
          </v-col>
          <v-col cols="12" sm="auto">
            <v-btn color="primary" @click="dialogMetasKpis = true">
              Gerenciar metas dos KPIs
              <v-icon class="ml-2">
                mdi-open-in-app
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mx-auto" justify="space-between">
          <v-col class="py-0" cols="12" sm="6">
            <div class="body-2">
              Tipo de cálculo:
            </div>
            <v-radio-group v-model="tipoCalculoSelecionado" row class="my-0" hide-details>
              <v-radio label="LNO" value="lno" />
              <v-radio label="GDIS" value="gdis" />
              <v-radio label="LNO e GDIS" value="lno_gdis" />
              <v-radio
              label="LNO + GDIS - Estudo"
              value="lno_gdis_estudo"
              />
              <v-radio
                label="LNO - Estudo"
                value="lno_estudo"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tabs-items
      v-model="tab"
      style="background-color: transparent !important;"
    >
      <v-tab-item>
        <dashboard-lno-geral
          v-if="competenciaDe != '' && competenciaAte != ''"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :tipoCalculo="tipoCalculo"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
          @updateLoadingDados="loadingDados = $event"
        />
      </v-tab-item>
      <v-tab-item>
        <dashboard-lno-visao-aneel
          v-if="competencia != ''"
          :competencia="competencia"
          :tipoCalculo="tipoCalculo"
          :categories="categories"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
          :regionalSelecionadaId="regionalSelecionadaId"
          @updateLoadingDados="loadingDados = $event"
        />
      </v-tab-item>
      <v-tab-item>
        <dashboard-lno-visao-municipios
          v-if="competencia != ''"
          :competencia="competencia"
          :tipoCalculo="tipoCalculo"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
          :regionalSelecionadaId="regionalSelecionadaId"
          @updateLoadingDados="loadingDados = $event"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-overlay :value="loadingDados" :opacity="0.85">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>

    <dialog-gerenciamento-metas-kpis
      v-if="dialogMetasKpis"
      :dialog.sync="dialogMetasKpis"
      ref="metasKpis"
      @importaMetasKpis="importaMetasKpis()"
      @baixarMetasKpis="baixarMetasKpis($event)"
    >
      <template v-slot:cadatro-geral-extra-info>
        Deve conter as colunas:
        <span class="font-weight-bold"
          >mes; ano; qlp; pla; pmea; pat1; pat2; pat3; pat4</span
        >
      </template>
      <template v-slot:cadatro-municipios-extra-info>
        Deve conter as colunas:
        <span class="font-weight-bold"
          >codigo_municipio; mes; ano; qlp; pla; pmea; pat1; pat2; pat3;
          pat4</span
        >
      </template>
    </dialog-gerenciamento-metas-kpis>
  </v-container>
</template>

<script>
  import DashboardLnoService from "@/services/DashboardLnoService";
  import MunicipiosService from "@/services/MunicipiosService";

  import { generateMonthlyDateRangeArray } from "@/utils/dateUtils.js";

  import Highcharts from "highcharts";
  import HighchartsNoData from "highcharts/modules/no-data-to-display";
  HighchartsNoData(Highcharts);
  import dayjs from "dayjs";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "DashboardLno",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
      DashboardLnoGeral: () =>
        import("@/components/dashboardLno/DashboardLnoGeral.vue"),
      DashboardLnoVisaoAneel: () =>
        import("@/components/dashboardLno/DashboardLnoVisaoAneel.vue"),
      DashboardLnoVisaoMunicipios: () =>
        import("@/components/dashboardLno/DashboardLnoVisaoMunicipios.vue"),
      DialogGerenciamentoMetasKpis: () =>
        import(
          "@/components/paineisGerenciais/DialogGerenciamentoMetasKpis.vue"
        ),
    },
    data: () => ({
      tab: 1,
      regionais:[],
      dialogMetasKpis: false,
      loadingDados: true,
      traducaoIndicadores: {
        qlp: "Quantidade de Ligações com obra pendentes não suspensas",
        pla: "Percentual de ligações com obra atrasadas",
        "pla+e": "Percentual de ligações com obra atrasadas (com Estudo)",
        pmea:
          "Prazo Médio Ligação Pendente P/ Elab. de Est., Orç. e Projetos Atrasado",
        pmea2:
          "Prazo Médio Ligação Pendente P/ Elab. de Est., Orç. e Projetos Atrasado (2)",
        pat1: "Prazo Médio de Ligação Atrasada do Tipo 1",
        pat2: "Prazo Médio de Ligação Atrasada do Tipo 2",
        pat3: "Prazo Médio de Ligação Atrasada do Tipo 3",
        pat4: "Prazo Médio de Ligação Atrasada do Tipo 4",
        ilp: "Índice de Ligação com Obra Pendente",
      },
    }),
    computed: {
      ...mapState('parametrosDashboardLno', [
        'competenciaDe',
        'competenciaAte',
        'competencia',
        'regionalSelecionada',
        'tipoCalculo'
      ]),
      regionaisList(){
        return this.regionais.map((el) => el.nome);
      },
      regionalSelecionadaId(){
        let regional = this.regionais.find((element) => element.nome == this.regionalSelecionada);
        return  regional == undefined ? 0: regional.id;
      },
      categories() {
        const startDate = "2023-01";
        const endDate = dayjs().format("YYYY-MM");

        return generateMonthlyDateRangeArray(startDate, endDate, "YYYY-MM");
      },
      tipoCalculoSelecionado: {
        get() {
          return this.tipoCalculo;
        },
        set(newValue) {
          this.setTipoCalculo(newValue);
        }
      }
    },
    beforeMount() {
      this.setRangeCompetencias();
    },
    mounted() {
      this.getRegionais();
    },
    methods: {
      ...mapActions('parametrosDashboardLno', [
        'setRangeCompetencias',
        'setCompetenciaDe',
        'setCompetenciaAte',
        'setCompetencia',
        'setRegionalSelecionada',
        'setTipoCalculo'
      ]),
      importaMetasKpis() {
        const ref = this.$refs.metasKpis;

        ref.loading = true;

        DashboardLnoService.importarMetasKpis(
          ref.tipoDeCadastro,
          ref.lastUploadedFile.id
        )
          .then((res) => {
            this.$toast.success("Metas salvas com sucesso", "", {
              position: "topRight",
            });
            ref.uploadedFiles = [];
            ref.dialogLocal = false;
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao salvar metas", "", {
              position: "topRight",
            });
          })
          .finally(() => (ref.loading = false));
      },
      baixarMetasKpis(tipoDeCadastro) {
        const ref = this.$refs.metasKpis;

        ref.loading = true;

        DashboardLnoService.baixarMetasKpis(tipoDeCadastro, {
          timeout: 60 * 60 * 1000,
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Metas dos KPIs - ${tipoDeCadastro}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao baixar Metas dos KPIs", "", {
              position: "topRight",
            });
          })
          .finally(() => (ref.loading = false));
      },
      getRegionais() {
        return MunicipiosService.getRegionais()
          .then(res => {
            this.regionais = res;
            if(this.regionalSelecionada == null){
              this.setRegionalSelecionada(res.find((el) => el.nome.toLowerCase() == 'distribuidora').nome);
            }
          })
          .catch(err => {
            throw err;
          });
      },
    },
    watch: {},
  };
</script>

<style>
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
</style>
