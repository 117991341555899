var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-row',{staticClass:"mt-5",attrs:{"dense":"","justify":"center"}},_vm._l((_vm.indicadoresFiltrados),function(indicador){return _c('v-col',{key:indicador,staticClass:"flex-md-grow-1",attrs:{"cols":"5","sm":"3","md":"auto"}},[_c('v-card',{staticClass:"my-1"},[_c('v-card-title',[(_vm.traducaoIndicadores[indicador] != undefined)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h5',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(indicador.replaceAll("_", " ")))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.traducaoIndicadores[indicador]))])]):_c('h5',[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(indicador.replaceAll("_", " ")))+" ")])],1),_c('v-card-text',[_c('div',{staticClass:"display-3"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTotais[indicador] || "-"))+" "),(['prp', 'pri'].includes(indicador))?_c('span',[_vm._v("%")]):_vm._e()])])],1)],1)}),1),_c('v-row',{staticClass:"mt-5",attrs:{"dense":"","justify":"center"}},[_vm._l((_vm.indicadores),function(indicador,idx){return _c('v-col',{key:indicador,attrs:{"cols":"12","md":"6","order":idx}},[_c('v-card',{staticClass:"my-1"},[_c('v-card-title',[(_vm.traducaoIndicadores[indicador] != undefined)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h5',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(indicador.replaceAll("_", " ")))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.traducaoIndicadores[indicador]))])]):_c('h5',[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(indicador.replaceAll("_", " ")))+" ")])],1),_c('v-card-text',[_c('column-chart-mensal',{attrs:{"dadosMensais":_vm.dadosMensais,"categories":_vm.categories,"indicador":indicador,"metasKpis":_vm.metasKpis}})],1)],1)],1)}),(_vm.aba === 'tecnicos')?_c('v-col',{attrs:{"cols":"12","md":"6","order":"0"}},[_c('v-card',{staticClass:"my-1"},[_c('v-card-title',[_c('h5',[_vm._v(" UCs não conforme ")])]),_c('v-card-text',[_c('stacked-column-chart-mensal',{attrs:{"seriesNames":[
              'amostrais_nc',
              'permanentes_nc',
              'eventuais_nc'
            ],"dadosMensais":_vm.dadosMensais,"categories":_vm.categories,"metasKpis":_vm.metasKpis}})],1)],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }