<template>
  <span>
    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="indicador in indicadoresFiltrados"
        :key="indicador"
        cols="5"
        sm="3"
        md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip
              v-if="traducaoIndicadores[indicador] != undefined"
              bottom
            >
              <template v-slot:activator="{ on }">
                <h5 v-on="on">
                  {{ indicador.replaceAll("_", " ") | toUpperCase }}
                </h5>
              </template>
              <span>{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <h5 v-else>
              {{ indicador.replaceAll("_", " ") | toUpperCase }}
            </h5>
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{
                dadosTotais[indicador] || "-" | parseNumberToFloatBrIgnoringNaN
              }}
              <span v-if="['prp', 'pri'].includes(indicador)">%</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center" class="mt-5">
      <v-col v-for="(indicador, idx) in indicadores" :key="indicador" cols="12" md="6" :order="idx">
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip
              v-if="traducaoIndicadores[indicador] != undefined"
              bottom
            >
              <template v-slot:activator="{ on }">
                <h5 v-on="on">
                  {{ indicador.replaceAll("_", " ") | toUpperCase }}
                </h5>
              </template>
              <span>{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <h5 v-else>
              {{ indicador.replaceAll("_", " ") | toUpperCase }}
            </h5>
          </v-card-title>
          <v-card-text>
            <column-chart-mensal
              :dadosMensais="dadosMensais"
              :categories="categories"
              :indicador="indicador"
              :metasKpis="metasKpis"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" order="0" v-if="aba === 'tecnicos'">
        <!-- arrumar essa gambiarra um dia -->
        <v-card class="my-1">
          <v-card-title>
            <h5>
              UCs não conforme
            </h5>
          </v-card-title>
          <v-card-text>
            <stacked-column-chart-mensal
              :seriesNames="[
                'amostrais_nc',
                'permanentes_nc',
                'eventuais_nc'
              ]"
              :dadosMensais="dadosMensais"
              :categories="categories"
              :metasKpis="metasKpis"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  export default {
    name: "LayoutVisaoGlobal",
    components: {
      ColumnChartMensal: () =>
        import(
          "@/components/dashboardNivelTensao/charts/ColumnChartMensal.vue"
        ),
      StackedColumnChartMensal: () =>
        import(
          "@/components/dashboardNivelTensao/charts/StackedColumnChartMensal.vue"
        ),
    },
    data: () => ({
      //
    }),
    computed: {
      indicadoresFiltrados() {
        const blackList = ["drce_mensal", "drpe_mensal", "ucs_nao_conforme"];

        return this.indicadores.filter(
          (indicador) => !blackList.includes(indicador)
        );
      },
    },
    props: {
      aba: {
        type: String,
        required: true,
      },
      indicadores: {
        type: Array,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      dadosTotais: {
        type: Object,
        required: true,
      },
      dadosMensais: {
        type: Object,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: () => {},
      },
      metasKpis: {
        type: Object,
        required: true,
      },
    },
  };
</script>
