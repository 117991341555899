<template>
  <v-container fluid>
    <div class="mt-n10"></div>
    <v-row
      v-for="categoria in categorias"
      :key="categoria.categoria"
      class="mt-10 mx-auto"
    >
      <v-col cols="12" class="pb-0">
        <h2>
          {{ categoria.categoria }}
        </h2>
      </v-col>
      <v-col
        class=""
        xs="12"
        sm="6"
        md="4"
        xl="3"
        v-for="(painel, _key) in categoria.paineis"
        :key="_key"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot:default="{ hover }">
              <v-card
                :class="!painel.ativo ? 'disabled-card' : ''"
                :style="hover && !painel.ativo ? 'cursor: not-allowed' : ''"
                id="caixa-painel"
                class="mx-2 d-flex justify-center"
                min-width="200px"
                height="100%"
                raised
                :color="
                  hover && painel.ativo ? 'grey lighten-2' : 'grey lighten-3'
                "
                elevation="1"
                :to="!painel.ativo ? '' : `/paineis-gerenciais/${painel.slug}`"
                v-bind="attrs"
                v-on="on"
              >
                <hint
                  v-if="!painel.ativo"
                  msgModal="Este relatório está inativo. Para mais informações, entre em contato com o administrador do sistema."
                />
                <v-row no-gutters align="center" justify="center">
                  <v-col cols="2">
                    <v-icon
                      class="my-auto d-flex justify-center"
                      :size="40"
                      color="primary"
                    >
                      {{ painel.icone }}
                    </v-icon>
                  </v-col>
                  <v-col cols="10">
                    <v-card-text class="mx-auto my-auto">
                      <div class="subtitle-2" style="word-break: normal">
                        {{ painel.nome | truncate(45) }}
                      </div>
                      <div class="caption text--secondary">
                        {{ painel.descricao ? painel.descricao : "" }}
                      </div>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </template>
          {{ painel.nome }}
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    components: {
      Hint: () => import("@/components/general/Hint"),
    },
    data: () => ({
      categorias: [
        //{
        //  categoria: "Painéis Nacionais",
        //  paineis: [
        //    {
        //      nome: "Painel Brasil",
        //      descricao: "Dados nacionais das distribuidoras",
        //      slug: "painel-brasil",
        //      ativo: true,
        //      icone: "mdi-chart-areaspline"
        //    },
        // ],
        //},
        {
          categoria: "Painéis Internos",
          paineis: [
            {
              nome: "Dashboard LNO",
              descricao: "Dashboard Ligação com Obras",
              slug: "dashboard-lno",
              ativo: true,
              icone: "mdi-chart-areaspline"
            },
            {
              nome: "Dashboard de Serviços",
              descricao: "Dashboard de Serviços",
              slug: "dashboard-servicos",
              ativo: true,
              icone: "mdi-chart-areaspline"
            },
            {
              nome: "Dashboard de Faturamento",
              descricao: "Dashboard de Faturamento",
              slug: "dashboard-faturamento",
              ativo: true,
              icone: "mdi-chart-areaspline"
            },
            {
              nome: "Dashboard Reclamação", // Antigo 'Dashboard Anexo I - SAC'
              descricao: "Dashboard Reclamação",
              slug: "dashboard-reclamacao", 
              ativo: true,
              icone: "mdi-chart-areaspline"
            },
            {
              nome: "Dashboard Nível de Tensão",
              descricao: "Dashboard Nível de Tensão",
              slug: "dashboard-nivel-tensao",
              ativo: true,
              icone: "mdi-chart-areaspline"
            },
          ],
        },
      ],
    }),
  };
</script>

<style>
  .disabled-card {
    opacity: 0.5;
    border-color: transparent !important;
  }
</style>
