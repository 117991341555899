<template>
  <span>
    <v-card class="mb-0 mt-n2 parametros-v-card">
      <v-row justify="start" class="mx-auto mt-0">
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-select
            class="ml-8"
            :items="indicadorList[_subTab]"
            :value="indicadorSelecionado"
            @change="setIndicadorSelecionado"
            label="Indicador"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-tabs v-model="_subTab" grow color="secundary">
      <v-tab>
        Técnicos
      </v-tab>
      <v-tab>
        Serviços
      </v-tab>
      <v-tab>
        Reclamação
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="_subTab"
      style="background-color: transparent !important;"
    >
      <v-tab-item>
        <layout-visao-geral
          :indicadorSelecionado="indicadorSelecionado"
          :dadosIndicadores="dadosIndicadores"
          :metasKpis="metasKpis"
          :categories="categories"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>

      <v-tab-item>
        <layout-visao-geral
          :indicadorSelecionado="indicadorSelecionado"
          :dadosIndicadores="dadosIndicadores"
          :metasKpis="metasKpis"
          :categories="categories"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>

      <v-tab-item>
        <layout-visao-geral
          :indicadorSelecionado="indicadorSelecionado"
          :dadosIndicadores="dadosIndicadores"
          :categories="categories"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>
    </v-tabs-items>
  </span>
</template>

<script>
  import DashboardNivelTensaoService from "@/services/DashboardNivelTensaoService.js";
import { mapActions, mapState } from "vuex";

  export default {
    name: "DashboardNivelTensaoVisaoGeral",
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      inspecoesSelecionadas: {
        type: Array,
        required: true,
      },
      medicoesSelecionadas: {
        type: Array,
        required: true,
      },
      origemMedicoesSelecionadas: {
        type: Array,
        required: true,
      },
      tensaoReferenciaSelecionadas: {
        type: Array,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
      subTab: {
        type: Number,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: () => {},
      },
    },
    components: {
      LayoutVisaoGeral: () =>
        import("@/components/dashboardNivelTensao/LayoutVisaoGeral.vue"),
    },
    data: () => ({
      indicadorList: {
        0: [
          { value: "plna", text: "PLNA" },
          { value: "drpe", text: "DRPe" },
          { value: "drce", text: "DRCe" },
        ],
        1: [
          { value: "psfpg", text: "PSFPg" },
          { value: "psfpt_crp", text: "PSFPt-CRP" },
          { value: "psfpt_cri", text: "PSFPt-CRI" },
          { value: "psfpt_rmp", text: "PSFPt-RMP" },
          { value: "psfpt_rmc", text: "PSFPt-RMC" },
          { value: "total_compensacao", text: "Total de Compensação" },
        ],
        2: [
          { value: "qrt", text: "QRT" },
          { value: "prp", text: "PRP" },
          { value: "qtd_reclamacoes_totais", text: "QTD Reclamações Totais" },
          {
            value: "qtd_reclamacoes_procedentes",
            text: "QTD Reclamações Procedentes",
          },
        ],
      },
      dadosIndicadores: {},
    }),
    computed: {
      ...mapState('parametrosDashboardNivelTensao', [
        'indicadorSelecionado',
      ]),
      formattedCompetencia() {
        const [year, month] = this.competencia.split("-");

        return `${month}/${year}`;
      },
      _subTab: {
        get() {
          return this.subTab;
        },
        set(newValue) {
          this.$emit("update:subTab", newValue);
        },
      },
    },
    mounted() {
      this.getDados();
    },
    methods: {
      ...mapActions('parametrosDashboardNivelTensao', [
        'setIndicadorSelecionado',
      ]),
      getDados() {
        // Apenas atualiza os dados se for a visão selecionada
        if (this.activatedTab !== 1) return;

        this.$emit("updateLoadingDados", true);

        Promise.all([this.getDadosMensais()])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      getDadosMensais() {
        if (this._subTab === 0) return this.getDadosMensaisTecnicos();
        if (this._subTab === 1) return this.getDadosMensaisServicos();
        if (this._subTab === 2) return this.getDadosMensaisReclamacao();
      },
      getDadosMensaisReclamacao() {
        return DashboardNivelTensaoService.getIndicadoresReclamacaoMensaisTodasEmpresas(
          this.competenciaDe,
          this.competenciaAte,
          this.inspecoesSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosIndicadores = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosMensaisTecnicos() {
        return DashboardNivelTensaoService.getIndicadoresTecnicosMensaisTodasEmpresas(
          this.competenciaDe,
          this.competenciaAte,
          this.medicoesSelecionadas.join(","),
          this.origemMedicoesSelecionadas.join(","),
          this.tensaoReferenciaSelecionadas.join(","),
        )
          .then((res) => {
            this.dadosIndicadores = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosMensaisServicos() {
        return DashboardNivelTensaoService.getIndicadoresServicosMensaisTodasEmpresas(
          this.competenciaDe,
          this.competenciaAte
        )
          .then((res) => {
            this.dadosIndicadores = res;
          })
          .catch((err) => {
            throw err;
          });
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      _subTab() {
        this.getDados();

        this.setIndicadorSelecionado(this.indicadorList[this._subTab][0].value);
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      },
    },
  };
</script>

<style>
  .v-card-title-icon {
    position: relative;
    top: -40px;
    transition: 0.5s ease;
  }
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
</style>
