<template>
  <div id="div-tabela-kpis-by-municipio">
    <v-text-field
      v-if="numMunicipios > 10"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mb-5 break-search"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      class="data-table-kpis"
      multi-sort
      dense
      :headers="headers"
      :items="dados"
      :search.sync="search"
      :sort-by="['plna']"
      :sort-desc="['plna']"
      :options="{
        itemsPerPage: 10,
      }"
      :headerProps="{
        sortByText: 'Ordenar Por',
      }"
      :hide-default-footer="numMunicipios <= 10"
      no-data-text="Nenhum registro disponível"
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="10"
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip bottom v-if="traducaoIndicadores[h.value] != null">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text.replaceAll("_", " ") }}</span>
          </template>
          <span>{{ traducaoIndicadores[h.value] }}</span>
        </v-tooltip>
        <span v-else>{{ h.text.replaceAll("_", " ") }}</span>
      </template>

      <template v-for="kpi in kpis" v-slot:[`item.${kpi}`]="{ item }">
        <span :key="kpi">
          {{
            item[kpi] == null
              ? "-"
              : item[kpi] | parseNumberToFloatBrIgnoringNaN
          }}
          <span
            v-if="
              [
                'plna',
                'prp',
                'psfpt_crp',
                'psfpt_cri',
                'psfpt_rmp',
                'psfpt_rmc',
                'psfpg',
              ].includes(kpi)
            "
          >
            %
          </span>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    name: "KpisByMunicipioDataTable",
    props: {
      dados: {
        type: Array,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: {},
      },
    },
    data: () => ({
      kpis: [
        "plna",
        "qrt",
        "drpe",
        "drce",
        "prp",
        "psfpt_crp",
        "psfpt_cri",
        "psfpt_rmp",
        "psfpt_rmc",
        "psfpg",
        "icnt",
        "irnt",
        "total_compensacao",
        "qtd_reclamacoes_totais",
      ],
      search: "",
    }),
    computed: {
      headers() {
        let headers = [
          {
            text: "IBGE",
            value: "codigo_municipio",
          },
          {
            text: "Município",
            value: "nome_municipio",
            width: "100px",
          },
        ];
        this.kpis.forEach((kpi) =>
          headers.push({
            text: kpi.toUpperCase(),
            value: kpi,
          })
        );
        return headers;
      },
      numMunicipios() {
        return this.dados.length;
      },
    },
    methods: {},
  };
</script>

<style>
  #div-tabela-kpis-by-municipio {
    min-height: 420px;
  }
  .data-table-kpis table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
  }
  .data-table-kpis table thead tr th {
    font-size: 12px;
    height: 25px;
    font-weight: 400;
  }
</style>
