<template>
  <span>
    <v-card class="mb-0 mt-n2 parametros-v-card">
      <v-row justify="start" class="mx-auto mt-0">
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-select
            class="ml-8"
            :items="regionaisList"
            :value="regionalSelecionada"
            @change="setRegionalSelecionada"
            label="Regional selecionado"
          />
        </v-col>
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-autocomplete
            class="ml-5"
            :items="listaMunicipios"
            v-model="municipioSelecionado"
            label="Município selecionado"
            clearable
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="indicador in [
          'qrt',
          'prp',
          'pri',
          'prazo_medio_total_reclamacoes_encerradas',
          'prazo_medio_total_reclamacoes_improcedentes_encerradas',
        ]"
        :key="indicador"
        cols="5"
        sm="3"
        md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip
              v-if="traducaoIndicadores[indicador] != undefined"
              bottom
            >
              <template v-slot:activator="{ on }">
                <h5 v-on="on">
                  {{ indicador.replaceAll("_", " ") | toUpperCase }}
                </h5>
              </template>
              <span>{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <h5 v-else>
              {{ indicador.replaceAll("_", " ") | toUpperCase }}
            </h5>
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{
                dadosMunicipioSelecionado == null ||
                dadosMunicipioSelecionado[indicador] == undefined
                  ? "-"
                  : dadosMunicipioSelecionado[indicador]
                    | parseNumberToFloatBrIgnoringNaN
              }}
              <span v-if="['prp', 'pri'].includes(indicador)">%</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="12" md="9" class="flex-md-grow-1">
        <v-card class="my-1">
          <v-card-text>
            <kpis-by-municipio-data-table
              :traducaoIndicadores="traducaoIndicadores"
              :dados="dadosPorMunicipio"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="auto" class="flex-md-grow-1">
        <v-card class="my-1">
          <v-card-text>
            <kpis-by-municipio-map :dadosPorMunicipio="dadosPorMunicipio" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Recebidas por Canal</h5>
          </v-card-title>
          <v-card-text>
            <chart-recebidas-por-canal :dadosPorCanal="dadosPorCanal" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Percentual de Reclamações Improcedentes por Canal</h5>
          </v-card-title>
          <v-card-text>
            <chart-prp-por-canal :dadosPorCanal="dadosPorCanal" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="12" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Quantidade Relativa de Reclamações por Tipologia</h5>
          </v-card-title>
          <v-card-text>
            <chart-qrt-por-tipologia
              :dadosPorTipologia="dadosPorTipologia"
              :dadosPorSubtipologia="dadosPorSubtipologia"
              :dadosSubTipologiasByTipologia="dadosSubTipologiasByTipologia"
              :traducaoTipologias="traducaoTipologias"
              :traducaoSubTipologias="traducaoSubTipologias"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Percentual de Reclamações Procedentes por Tipologia</h5>
          </v-card-title>
          <v-card-text>
            <chart-prp-por-tipologia
              :dadosPorTipologia="dadosPorTipologia"
              :dadosPorSubtipologia="dadosPorSubtipologia"
              :dadosSubTipologiasByTipologia="dadosSubTipologiasByTipologia"
              :traducaoTipologias="traducaoTipologias"
              :traducaoSubTipologias="traducaoSubTipologias"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="12" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Recebidas por Tipologia</h5>
          </v-card-title>
          <v-card-text>
            <chart-recebidas-por-tipologia
              :dadosPorTipologia="dadosPorTipologia"
              :dadosPorSubtipologia="dadosPorSubtipologia"
              :dadosSubTipologiasByTipologia="dadosSubTipologiasByTipologia"
              :traducaoTipologias="traducaoTipologias"
              :traducaoSubTipologias="traducaoSubTipologias"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Total de Reclamação por Tipologia</h5>
          </v-card-title>
          <v-card-text>
            <chart-total-reclamacao-por-tipologia
              :dadosPorTipologia="dadosPorTipologia"
              :dadosPorSubtipologia="dadosPorSubtipologia"
              :dadosSubTipologiasByTipologia="dadosSubTipologiasByTipologia"
              :traducaoTipologias="traducaoTipologias"
              :traducaoSubTipologias="traducaoSubTipologias"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Quantidade Relativa de Reclamações por Competência</h5>
          </v-card-title>
          <v-card-text>
            <chart-qrt-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Percentual de Reclamações Procedentes por Competência</h5>
          </v-card-title>
          <v-card-text>
            <chart-prp-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>Total de Reclamação Mês a Mês</h5>
          </v-card-title>
          <v-card-text>
            <chart-total-reclamacao-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Prazo Médio Total de Reclamações Encerradas por Competência</h5>
          </v-card-title>
          <v-card-text>
            <chart-prazo-medio-total-reclamacoes-encerradas-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>
              Prazo Médio de Reclamações Improcedentes Encerradas por
              Competência
            </h5>
          </v-card-title>
          <v-card-text>
            <chart-prazo-medio-reclamacoes-improcedentes-encerradas-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  import DashboardAnexo1Service from "@/services/DashboardAnexo1Service";
  import MunicipiosService from "@/services/MunicipiosService";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "DashboardAnexo1VisaoMunicipios",
    components: {
      KpisByMunicipioDataTable: () =>
        import("@/components/dashboardAnexo1/KpisByMunicipioDataTable.vue"),
      KpisByMunicipioMap: () =>
        import("@/components/dashboardAnexo1/KpisByMunicipioMap.vue"),
      ChartRecebidasPorCanal: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartRecebidasPorCanal.vue"
        ),
      ChartPrpPorCanal: () =>
        import("@/components/dashboardAnexo1/charts/ChartPrpPorCanal.vue"),
      ChartQrtPorTipologia: () =>
        import("@/components/dashboardAnexo1/charts/ChartQrtPorTipologia.vue"),
      ChartPrpPorTipologia: () =>
        import("@/components/dashboardAnexo1/charts/ChartPrpPorTipologia.vue"),
      ChartRecebidasPorTipologia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartRecebidasPorTipologia.vue"
        ),
      ChartTotalReclamacaoPorTipologia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartTotalReclamacaoPorTipologia.vue"
        ),
      ChartQrtPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartQrtPorCompetencia.vue"
        ),
      ChartPrpPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartPrpPorCompetencia.vue"
        ),
      ChartTotalReclamacaoPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartTotalReclamacaoPorCompetencia.vue"
        ),
      ChartPrazoMedioReclamacoesImprocedentesEncerradasPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartPrazoMedioReclamacoesImprocedentesEncerradasPorCompetencia.vue"
        ),
      ChartPrazoMedioTotalReclamacoesEncerradasPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartPrazoMedioTotalReclamacoesEncerradasPorCompetencia.vue"
        ),
    },
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: {},
      },
      traducaoTipologias: {
        type: Object,
        default: () => {},
      },
      traducaoSubTipologias: {
        type: Object,
        default: () => {},
      },
      canaisSelecionados: {
        type: Array,
        default: () => []
      },
      niveisSelecionados: {
        type: Array,
        default: () => []
      },
      tipologiasSelecionadas: {
        type: Array,
        default: () => []
      },
      subtipologiasSelecionadas: {
        type: Array,
        default: () => []
      },
    },
    data: () => ({
      regionais: [],
      listaMunicipios: [],
      dadosTotais: {},
      dadosPorMunicipio: [],
      dadosPorCompetencia: {},
      dadosPorCanal: {},
      dadosPorTipologia: {},
      dadosPorSubtipologia: {},
      exportingOptions: {
        enabled: true,
        buttons: {
          contextButton: {
            align: "right",
            verticalAlign: "bottom",
            menuItems: [
              {
                textKey: "downloadPNG",
                text: "Baixar como PNG",
                onclick: function() {
                  this.exportChart();
                },
              },
              {
                separator: true,
              },
              {
                textKey: "downloadCSV",
                text: "Baixar como CSV",
                onclick: function() {
                  this.downloadCSV();
                },
              },
              {
                textKey: "downloadXLS",
                text: "Baixar como XLS",
                onclick: function() {
                  this.downloadXLS();
                },
              },
            ],
          },
        },
      },
    }),
    computed: {
      ...mapState('parametrosDashboardAnexo1', [
        'regionalSelecionada',
        'municipio'
      ]),
      municipioSelecionado: {
        get() {
          return this.municipio;
        },
        set(newValue) {
          this.setMunicipio(newValue);
        }
      },
      regionaisList() {
        return this.regionais.map((el) => el.nome);
      },
      regionalSelecionadaId() {
        let regional = this.regionais.find(
          (element) => element.nome == this.regionalSelecionada
        );
        return regional == undefined ? 0 : regional.id;
      },
      dadosMunicipioSelecionado() {
        if (this.municipioSelecionado == null) {
          return this.dadosTotais;
        }

        return this.dadosPorMunicipio.find(
          (el) => el.nome_municipio === this.municipioSelecionado
        );
      },
      dadosSubTipologiasByTipologia() {
        let dadosSubTipologiasByTipologia = {};

        Object.keys(this.dadosPorSubtipologia).forEach((subtipologia) => {
          if (
            Object.keys(this.dadosPorTipologia).some((tipologia) =>
              subtipologia.startsWith(tipologia)
            )
          ) {
            const tipologia = subtipologia.substring(0, 5);
            if (!dadosSubTipologiasByTipologia[tipologia]) {
              dadosSubTipologiasByTipologia[tipologia] = [];
            }

            dadosSubTipologiasByTipologia[tipologia].push(
              this.dadosPorSubtipologia[subtipologia]
            );
          }
        });

        return dadosSubTipologiasByTipologia;
      },
    },
    mounted() {
      this.getRegionais(this.regionalSelecionadaId);
      this.getDados();
    },
    methods: {
      ...mapActions('parametrosDashboardAnexo1', [
        'setRegionalSelecionada',
        'setMunicipio',
      ]),
      getRegionais(regional) {
        return MunicipiosService.getRegionais(regional)
          .then((res) => {
            this.regionais = res;
            if (this.regionalSelecionada == null) {
              this.setRegionalSelecionada(res.find(
                (el) => el.nome.toLowerCase() == "distribuidora"
              ).nome);
            }
          })
          .catch((err) => {
            throw err;
          });
      },
      getDados() {
        // Apenas atualizado os dados se for a visao selecionada
        if (this.activatedTab !== 2) return;

        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getDadosPorMunicipio(),
          this.getDadosTotais(),
          this.getDadosPorCompetencia(),
          this.getDadosPorCanal(),
          this.getDadosPorTipologia(),
        ])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      getDadosPorMunicipio() {
        return DashboardAnexo1Service.getDadosIndicadoresPorMunicipio(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosPorMunicipio = res;

            this.listaMunicipios = this.dadosPorMunicipio
              .map((el) => el.nome_municipio)
              .sort((a, b) => Number(a ?? 0) - Number(b ?? 0));
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosTotais() {
        return DashboardAnexo1Service.getDadosTotaisIndicadores(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosTotais = res[0] || null;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorCompetencia() {
        return DashboardAnexo1Service.getDadosIndicadoresPorCompetencia(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosPorCompetencia = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorCanal() {
        return DashboardAnexo1Service.getDadosIndicadoresPorCanal(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosPorCanal = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorTipologia() {
        return DashboardAnexo1Service.getDadosIndicadoresPorTipologia(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then(async (res) => {
            this.dadosPorTipologia = res;

            await this.getDadosPorSubtipologia();
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorSubtipologia() {
        return DashboardAnexo1Service.getDadosIndicadoresPorSubtipologia(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosPorSubtipologia = res;
          })
          .catch((err) => {
            throw err;
          });
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      },
      regionalSelecionada() {
        this.getDados();
      },
    },
  };
</script>

<style>
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
</style>
