<template>
  <span>
    <v-card class="mb-0 mt-n2 parametros-v-card">
      <v-row justify="start" class="mx-auto mt-0">
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-select
            class="ml-8"
            :items="regionaisList"
            :value="regionalSelecionada"
            @change="setRegionalSelecionada"
            label="Regional selecionado"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="indicador in Object.keys(dadosTotais)"
        :key="indicador"
        cols="5"
        sm="3"
        md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip
              v-if="traducaoIndicadores[indicador] != undefined"
              bottom
            >
              <template v-slot:activator="{ on }">
                <h5 v-on="on">
                  {{ indicador.replaceAll("_", " ") | toUpperCase }}
                </h5>
              </template>
              <span>{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <h5 v-else>
              {{ indicador.replaceAll("_", " ") | toUpperCase }}
            </h5>
            <!-- <status-evolucao-kpis
              class="ml-1"
              :valorAtual="dadosTotais[indicador] || 0"
              :valorAnterior="dadosTotaisCompetenciaAnterior[indicador] || 0"
            /> -->
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{
                dadosTotais[indicador] || "-" | parseNumberToFloatBrIgnoringNaN
              }}
              <span v-if="['prp', 'pri'].includes(indicador)">%</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Recebidas por Canal</h5>
          </v-card-title>
          <v-card-text>
            <chart-recebidas-por-canal :dadosPorCanal="dadosPorCanal" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Percentual de Reclamações Improcedentes por Canal</h5>
          </v-card-title>
          <v-card-text>
            <chart-prp-por-canal :dadosPorCanal="dadosPorCanal" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="12" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Quantidade Relativa de Reclamações por Tipologia</h5>
          </v-card-title>
          <v-card-text>
            <chart-qrt-por-tipologia
              :dadosPorTipologia="dadosPorTipologia"
              :dadosPorSubtipologia="dadosPorSubtipologia"
              :dadosSubTipologiasByTipologia="dadosSubTipologiasByTipologia"
              :traducaoTipologias="traducaoTipologias"
              :traducaoSubTipologias="traducaoSubTipologias"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Percentual de Reclamações Procedentes por Tipologia</h5>
          </v-card-title>
          <v-card-text>
            <chart-prp-por-tipologia
              :dadosPorTipologia="dadosPorTipologia"
              :dadosPorSubtipologia="dadosPorSubtipologia"
              :dadosSubTipologiasByTipologia="dadosSubTipologiasByTipologia"
              :traducaoTipologias="traducaoTipologias"
              :traducaoSubTipologias="traducaoSubTipologias"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="12" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Recebidas por Tipologia</h5>
          </v-card-title>
          <v-card-text>
            <chart-recebidas-por-tipologia
              :dadosPorTipologia="dadosPorTipologia"
              :dadosPorSubtipologia="dadosPorSubtipologia"
              :dadosSubTipologiasByTipologia="dadosSubTipologiasByTipologia"
              :traducaoTipologias="traducaoTipologias"
              :traducaoSubTipologias="traducaoSubTipologias"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Total de Reclamação por Tipologia</h5>
          </v-card-title>
          <v-card-text>
            <chart-total-reclamacao-por-tipologia
              :dadosPorTipologia="dadosPorTipologia"
              :dadosPorSubtipologia="dadosPorSubtipologia"
              :dadosSubTipologiasByTipologia="dadosSubTipologiasByTipologia"
              :traducaoTipologias="traducaoTipologias"
              :traducaoSubTipologias="traducaoSubTipologias"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Quantidade Relativa de Reclamações por Competência</h5>
          </v-card-title>
          <v-card-text>
            <chart-qrt-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Percentual de Reclamações Procedentes por Competência</h5>
          </v-card-title>
          <v-card-text>
            <chart-prp-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12">
        <v-card class="my-1">
          <v-card-title>
            <h5>Total de Reclamação Mês a Mês</h5>
          </v-card-title>
          <v-card-text>
            <chart-total-reclamacao-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="start">
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Prazo Médio Total de Reclamações Encerradas por Competência</h5>
          </v-card-title>
          <v-card-text>
            <chart-prazo-medio-total-reclamacoes-encerradas-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>
              Prazo Médio de Reclamações Improcedentes Encerradas por
              Competência
            </h5>
          </v-card-title>
          <v-card-text>
            <chart-prazo-medio-reclamacoes-improcedentes-encerradas-por-competencia
              :dadosPorCompetencia="dadosPorCompetencia"
              :categories="categories"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  import DashboardAnexo1Service from "@/services/DashboardAnexo1Service.js";
  import MunicipiosService from '@/services/MunicipiosService.js';
  import { mapState, mapActions } from 'vuex';

  export default {
    name: "DashboardAnexo1VisaoAneel",
    components: {
      ChartRecebidasPorCanal: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartRecebidasPorCanal.vue"
        ),
      ChartPrpPorCanal: () =>
        import("@/components/dashboardAnexo1/charts/ChartPrpPorCanal.vue"),
      ChartQrtPorTipologia: () =>
        import("@/components/dashboardAnexo1/charts/ChartQrtPorTipologia.vue"),
      ChartPrpPorTipologia: () =>
        import("@/components/dashboardAnexo1/charts/ChartPrpPorTipologia.vue"),
      ChartRecebidasPorTipologia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartRecebidasPorTipologia.vue"
        ),
      ChartTotalReclamacaoPorTipologia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartTotalReclamacaoPorTipologia.vue"
        ),
      ChartQrtPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartQrtPorCompetencia.vue"
        ),
      ChartPrpPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartPrpPorCompetencia.vue"
        ),
      ChartTotalReclamacaoPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartTotalReclamacaoPorCompetencia.vue"
        ),
      ChartPrazoMedioReclamacoesImprocedentesEncerradasPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartPrazoMedioReclamacoesImprocedentesEncerradasPorCompetencia.vue"
        ),
      ChartPrazoMedioTotalReclamacoesEncerradasPorCompetencia: () =>
        import(
          "@/components/dashboardAnexo1/charts/ChartPrazoMedioTotalReclamacoesEncerradasPorCompetencia.vue"
        ),
    },
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: () => {},
      },
      traducaoTipologias: {
        type: Object,
        default: () => {},
      },
      traducaoSubTipologias: {
        type: Object,
        default: () => {},
      },
      canaisSelecionados: {
        type: Array,
        default: () => []
      },
      niveisSelecionados: {
        type: Array,
        default: () => []
      },
      tipologiasSelecionadas: {
        type: Array,
        default: () => []
      },
      subtipologiasSelecionadas: {
        type: Array,
        default: () => []
      },
    },
    data: () => ({
      dadosTotais: {},
      dadosPorCompetencia: {},
      dadosPorCanal: {},
      dadosPorTipologia: {},
      dadosPorSubtipologia: {},
      regionais: [],
    }),
    computed: {
      ...mapState('parametrosDashboardAnexo1', [
        'regionalSelecionada',
      ]),
      dadosSubTipologiasByTipologia() {
        let dadosSubTipologiasByTipologia = {};

        Object.keys(this.dadosPorSubtipologia).forEach((subtipologia) => {
          if (
            Object.keys(this.dadosPorTipologia).some((tipologia) =>
              subtipologia.startsWith(tipologia)
            )
          ) {
            const tipologia = subtipologia.substring(0, 5);
            if (!dadosSubTipologiasByTipologia[tipologia]) {
              dadosSubTipologiasByTipologia[tipologia] = [];
            }

            dadosSubTipologiasByTipologia[tipologia].push(
              this.dadosPorSubtipologia[subtipologia]
            );
          }
        });

        return dadosSubTipologiasByTipologia;
      },
      regionaisList() {
        return this.regionais.map((el) => el.nome);
      },
      regionalSelecionadaId() {
        let regional = this.regionais.find(
          (element) => element.nome == this.regionalSelecionada
        );
        return regional == undefined ? 0 : regional.id;
      },
    },
    mounted() {
      this.getRegionais();
      this.getDados();
    },
    methods: {
      ...mapActions('parametrosDashboardAnexo1', [
        'setRegionalSelecionada',
      ]),
      getDados() {
        // Apenas atualizado os dados se for a visao selecionada
        if (this.activatedTab !== 1) return;

        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getDadosTotalizados(),
          this.getDadosPorCompetencia(),
          this.getDadosPorCanal(),
          this.getDadosPorTipologia(),
        ])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      getDadosTotalizados() {
        return DashboardAnexo1Service.getDadosIndicadoresTotalizados(
          this.competenciaDe,
          this.competenciaAte,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(","),
          this.regionalSelecionadaId
        )
          .then((res) => {
            const dados = res[0];

            this.dadosTotais = {
              qrt: dados.qrt,
              prp: dados.prp,
              pri: dados.pri,
              prazo_medio_total_reclamacoes_encerradas:
                dados.prazo_medio_total_reclamacoes_encerradas,
              prazo_medio_total_reclamacoes_improcedentes_encerradas:
                dados.prazo_medio_total_reclamacoes_improcedentes_encerradas,
              der_fer: dados.der_fer,
            };
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorCompetencia() {
        return DashboardAnexo1Service.getDadosIndicadoresPorCompetencia(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosPorCompetencia = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorCanal() {
        return DashboardAnexo1Service.getDadosIndicadoresPorCanal(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosPorCanal = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorTipologia() {
        return DashboardAnexo1Service.getDadosIndicadoresPorTipologia(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then(async (res) => {
            this.dadosPorTipologia = res;

            await this.getDadosPorSubtipologia();
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorSubtipologia() {
        return DashboardAnexo1Service.getDadosIndicadoresPorSubtipologia(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dadosPorSubtipologia = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getRegionais() {
        return MunicipiosService.getRegionais()
          .then((res) => {
            this.regionais = res;
            if (this.regionalSelecionada == null) {
              this.setRegionalSelecionada(res.find(
                (el) => el.nome.toLowerCase() == "distribuidora"
              ).nome);
            }
          })
          .catch((err) => {
            throw err;
          });
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      },
      regionalSelecionada() {
        this.getDados();
      },
    },
  };
</script>

<style>
  .table-indicadores {
    margin: 10px 0;
    padding: 10px;
  }

  .table-indicadores table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
    text-align: end;
  }

  .table-indicadores table thead tr th {
    font-size: 12px;
    height: 25px;
    text-align: end !important;
    font-weight: 500;
  }

  .v-card-title-icon {
    position: relative;
    top: -40px;
    transition: 0.5s ease;
  }
</style>
