<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie o arquivo de dados a ser importado
    </div>

    <v-row id="importacao-encoding" class="mx-auto">
      <v-col cols="12">
        <select-encoding
          id="fs-encoding-importacao-dados"
          label="Codificação do arquivo"
          type="outlined"
          :selected="encoding"
          @encoding:selected="encodingSelected"
        />
      </v-col>
    </v-row>

    <v-row id="importacao-upload-files">
      <v-col cols="12">
        <upload-files
          id="fs-upload-importacao-dados"
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        >
          <template v-slot:upload-alert-extra-information>
            <div>
              <a
                href="javascript:void(0)"
                class="text-decoration-none"
                @click="baixarArquivoModelo()"
              >
                Clique aqui
              </a>
              para baixar o modelo para a importação
              <v-progress-circular
                v-if="downloadingModelo"
                size="20"
                indeterminate
                color="rgba(0, 0, 0, 0.87)"
              />
            </div>
          </template>
        </upload-files>
      </v-col>
    </v-row>
    <v-row v-if="lastUploadedFile">
      <v-col cols="12">
        <v-text-field
          label="Arquivo enviado"
          outlined
          readonly
          v-model="lastUploadedFile.arquivo"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ImportacaoNivelTensaoService from "@/services/ImportacaoNivelTensaoService";

  export default {
    name: "SecondTabContent",
    components: {
      SelectEncoding: () => import("@/components/general/SelectEncoding.vue"),
      UploadFiles: () => import("@/components/general/UploadFiles/Index.vue"),
      LinkDownloadArquivoModelo: () =>
        import(
          "@/components/administracao/importacoes-dados/LinkDownloadArquivoModelo.vue"
        ),
    },
    props: {
      tipoImportacao: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        encoding: "WINDOWS-1252",
        allowedFiles: [],
        uploadedFiles: [],
        apagarBaseAtual: false,
        selectAllCategories: false,
        selectedCategories: [],
        downloadingModelo: false,
      };
    },
    mounted() {
      this.getAllowedFiles();
    },
    watch: {
      encoding() {
        this.$emit("encoding:selected", this.encoding);
      },
      uploadedFiles() {
        this.$emit("file:uploaded", this.lastUploadedFile);
      },
    },
    computed: {
      lastUploadedFile() {
        return this.uploadedFiles[this.uploadedFiles.length - 1];
      },
    },
    methods: {
      getAllowedFiles() {
        ImportacaoNivelTensaoService.getAllowedFiles()
          .then((response) => (this.allowedFiles = response.data))
          .catch((error) => console.log("Error: " + error));
      },
      handleFileUploaded(event) {
        const { files } = event;
        files.forEach((file) => this.uploadedFiles.push(file));
      },
      encodingSelected(event) {
        this.encoding = event;
      },
      baixarArquivoModelo() {
        this.loading = true;

        ImportacaoNivelTensaoService.baixarArquivoModelo(this.tipoImportacao)
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${this.tipoImportacao}.csv`);
            document.body.appendChild(link);
            link.click();
          })
          .catch(() => {
            this.$toast.error("Falha no download do arquivo modelo.", "", {
              position: "topRight",
            });
          })
          .finally(() => (this.loading = false));
      },
    },
  };
</script>
